import React, {useEffect, useRef} from "react";
import {Wrapper, FPItem, FPImage, FPInfo, FPItems} from './index.styled'
import { FloorPlansData } from "../../../data/floor-plans"
import useWindowSize from "../../../helpers/windowSize";
import Image from "../../image";
import {sizes} from "../../../helpers/MediaQueries";

const ContentFpAm = () => {
const fpItemsWrapperRef = useRef<HTMLDivElement>(null);
const fpItemsRef = useRef<HTMLDivElement>(null);
const fpItem1 = useRef<HTMLDivElement>(null);
const fpItem2 = useRef<HTMLDivElement>(null);
const fpImg2 = useRef<HTMLDivElement>(null);
const {width} = useWindowSize();

  const fpItemShow = (fpItem: React.RefObject<HTMLDivElement>) => {
    if(fpItem.current) fpItem.current.style.opacity = '1';
    if(fpItem.current) fpItem.current.style.visibility = 'visible';
  }
  const fpItemHide = (fpItem: React.RefObject<HTMLDivElement>) => {
    if(fpItem.current) fpItem.current.style.opacity = '0';
    if(fpItem.current) fpItem.current.style.visibility = 'hidden';
  }
  useEffect(()=> {
    fpItemShow(fpItem1);

    // gsap.timeline({
    //   scrollTrigger: {
    //     trigger: fpItemsWrapperRef.current || "",
    //     start: "top-=5% bottom",
    //     end: "top+=10% top",
    //     scrub: true,
    //     toggleActions: "play none none reverse",
    //     onEnter: () => fpItemShow(fpItem1),
    //     onEnterBack: () => fpItemShow(fpItem1),
    //   }
    // })
  //   gsap.timeline({
  //     scrollTrigger: {
  //       trigger: fpItemsWrapperRef.current || "",
  //       start: "top+=10% top",
  //       end: "top+=100% top",
  //       scrub: true,
  //       toggleActions: "play none none reverse",
  //       onEnter: () => fpItemShow(fpItem2),
  //       onEnterBack: () => fpItemShow(fpItem2),
  //       onLeave: () => fpItemHide(fpItem2),
  //       onLeaveBack: () => fpItemHide(fpItem2)
  //     }
  //   })
  //   gsap.timeline({
  //     scrollTrigger: {
  //       trigger: fpItemsWrapperRef.current || "",
  //       start: "bottom bottom-=30%",
  //       end: "bottom top",
  //       scrub: true,
  //       toggleActions: "play none none reverse",
  //     }
  //   })
  //     .to(fpImg2.current||'', {y: '-20vh'}, 0)
  //     .to(fpItemsWrapperRef.current||'', {y: '-100vh'}, 0)
  }, [])

  return (
    <Wrapper ref={fpItemsWrapperRef}>
      <FPItems ref={fpItemsRef}>
        <FPItem ref={fpItem1}>
          <FPImage>
            <Image imageName={'floor-plan-gl1.png'}/>
            {width > sizes.tablet &&
              <a href={"/Maple_Terrace-Amenities-AP_GL.pdf"} className="download fit" target="_blank">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white"/>
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white"/>
                </svg>
                DOWNLOAD AMENITY PLAN
              </a>
            }
          </FPImage>
          <FPInfo>
            <div className="fp-info-level">
              <h5
                onClick={()=> {
                  fpItemShow(fpItem1)
                  fpItemHide(fpItem2)
                }}
                className={'active'}>Ground Level</h5>
              <h5 onClick={()=> {
                fpItemShow(fpItem2)
                fpItemHide(fpItem1)
              }}>Level 8</h5>
              { width > sizes.tablet &&
                <a href={"/all_mt_ap.zip"} className="download all">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD ALL
              </a>}
            </div>
            <div className="fp-info-items">
              {width > sizes.phoneXL ?
                <>
                  <div>
                    {FloorPlansData.floorPlan4.info[0].map((item, i) => (
                      <p className={"caption_t1"} key={`idf-${i}`}>
                        {item}
                      </p>
                    ))}
                  </div>
                  <div>
                    {FloorPlansData.floorPlan4.info[1].map((item, i) => (
                      <p className={"caption_t1"} key={`idf-${i}`}>
                        {item}
                      </p>
                    ))}
                  </div>
                  <div>
                    {FloorPlansData.floorPlan4.info[2].map((item, i) => (
                      <p className={"caption_t1"} key={`idf-${i}`}>
                        {item}
                      </p>
                    ))}
                  </div>
                </>
              :
                <>
                  <div>
                    {FloorPlansData.floorPlan4.infoMob[0].map((item, i) => (
                      <p className={"caption_t1"} key={`idf-${i}`}>
                        {item}
                      </p>
                    ))}
                  </div>
                  <div>
                    {FloorPlansData.floorPlan4.infoMob[1].map((item, i) => (
                      <p className={"caption_t1"} key={`idf-${i}`}>
                        {item}
                      </p>
                    ))}
                  </div>
                </>
              }
            </div>
            <div className="thumb"/>
          </FPInfo>
          {width <= sizes.phoneXL &&
            <a href={"/all_mt_ap.zip"} className="download all">
              <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
              </svg>
              DOWNLOAD ALL
            </a>}
        </FPItem>
        <FPItem ref={fpItem2}>
          <FPImage ref={fpImg2}>
            <Image imageName={'floor-plan-l_8.png'}/>
            {width > sizes.tablet &&
              <a href={"/Maple_Terrace-Amenities-AP_L8.pdf"} className="download fit" target="_blank">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white"/>
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white"/>
                </svg>
                DOWNLOAD AMENITY PLAN
              </a>
            }
          </FPImage>
          <FPInfo>
            <div className="fp-info-level">
              <h5 onClick={()=> {
                  fpItemShow(fpItem1)
                  fpItemHide(fpItem2)
                }}
              >Ground Level</h5>
              <h5
                onClick={()=> {
                  fpItemShow(fpItem1)
                  fpItemHide(fpItem2)
                }}
                className={'active'}>Level 8</h5>
              {width > sizes.tablet &&
                <a href={"/all_mt_ap.zip"} className="download all">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD ALL
              </a>}
            </div>
            <div className="fp-info-items one-r">
              {FloorPlansData.floorPlan5.info[0].map((item, i) => (
                <p className={"caption_t1 top-align"} key={`idf-${i}`}>
                  {item}
                </p>
              ))}
            </div>
            <div className="thumb"/>
          </FPInfo>
          {width <= sizes.phoneXL &&
            <a href={"/all_mt_ap.zip"} className="download all">
              <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
              </svg>
              DOWNLOAD ALL
            </a>}
        </FPItem>
      </FPItems>
    </Wrapper>
  )
}
export default ContentFpAm