import styled from "styled-components"
import {mediaMax, mediaMin} from "../../../helpers/MediaQueries";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50vh;
  ${mediaMax.phoneXL`
    height: auto;
    padding: 36px 24px;
  `}
  
  .content {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    ${mediaMax.phoneXL`
      flex-direction: column;
    `}
  }
`
export const Headings = styled.div`
  max-width: 50%;
  transform: translate(0, -100px);
  opacity: 0;
  ${mediaMax.phoneXL`
    opacity: 1;
    max-width: 100%;
    transform: translate(0);
  `}
  h3 {
    font-weight: 300;
    max-width: 500px;
    line-height: 45px;
    ${mediaMax.phoneXL`
      letter-spacing: -0.01em;
    `}
    &.h3i {
      ${mediaMin.phoneXL`
        br.m {
          display: none; 
        }
      `}
    }
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  ${mediaMax.phoneXL`
    opacity: 1;
  `}
  p {
    line-height: 24px;
    padding: 0 10px;
    max-width: 430px;
    ${mediaMax.phoneXL`
      line-height: 1.3;
      padding: 24px 0 0;
    `}
  }
`
