import React from "react"
import floorPlanImg1 from "../assets/images/building/floor-plan-l_4.png"
import floorPlanImg2 from "../assets/images/building/floor-plan-l_5.png"
import floorPlanImg3 from "../assets/images/building/floor-plan-l_6.png"
import floorPlanImg4 from "../assets/images/amenities/floor-plan-gl1.png"
import floorPlanImg5 from "../assets/images/amenities/floor-plan-l_8.png"
export const FloorPlansData = {
  floorPlan1: {
    img: floorPlanImg1,
    info: [
      <>HYPOTHETICAL <br /> FLOOR PLATE 1</>,
      <>SQUARE Ft per person <br /> 363 SF</>,
      <>number Offices <br /> 11</>,
      <>receptions <br /> 2</>,
      <>MEDIUM DENSITY</>,
      <>total headcount 41</>,
      <>Workstations <br /> 28</>,
      <></>,
      <>OVERALL SQUARE FT <br /> 14,900 SF</>,
    ],
    infoMob: [
      <>HYPOTHETICAL FLOOR PLATE 1</>,
      <>MEDIUM DENSITY</>,
      <>OVERALL SQUARE FT <span>14,900 SF</span></>,
      <>SQUARE Ft per person <span>363 SF</span></>,
      <>total headcount <span>41</span></>,
      <>number Offices <span>11</span></>,
      <>Workstations <span>28</span></>,
      <>receptions <span>2</span></>,
    ],
  },
  floorPlan2: {
    img: floorPlanImg2,
    info: [
      <>HYPOTHETICAL <br /> FLOOR PLATE 2A</>,
      <>SQUARE Ft per person <br /> 339 SF</>,
      <>number Offices <br /> 19</>,
      <>ADMIN <br /> 2</>,
      <>COMBO FLOOR</>,
      <>total headcount <br /> 63</>,
      <>Workstations <br /> 28</>,
      <>Focus <br /> 13</>,
      <>OVERALL SQUARE FT <br /> 21,400 SF</>,
      <></>,
      <>receptions <br /> 1</>,
    ],
    infoMob: [
      <>HYPOTHETICAL FLOOR PLATE 2A</>,
      <>COMBO FLOOR</>,
      <>OVERALL SQUARE FT <span>21,400 SF</span></>,
      <>SQUARE Ft per person <span>339 SF</span></>,
      <>total headcount <span>63</span></>,
      <>number Offices  <span>19</span></>,
      <>Workstations <span>28</span></>,
      <>receptions <span>1</span></>,
      <>ADMIN  <span>2</span></>,
      <>Focus <span>13</span></>,
    ],
  },
  floorPlan3: {
    img: floorPlanImg3,
    info: [
      <>HYPOTHETICAL <br /> FLOOR PLATE 2B</>,
      <>SQUARE Ft per person <br /> 248 SF</>,
      <>number Offices <br /> 16</>,
      <>ADMIN <br /> 2</>,
      <>COMBO FLOOR</>,
      <>total headcount 60</>,
      <>Workstations <br /> 34</>,
      <>Focus <br /> 8</>,
      <>OVERALL SQUARE FT <br /> 14,900 SF</>,
    ],
    infoMob: [
      <>HYPOTHETICAL FLOOR PLATE 2B</>,
      <>COMBO FLOOR</>,
      <>OVERALL SQUARE FT <span>14,900 SF</span></>,
      <>SQUARE Ft per person <span>248 SF</span></>,
      <>total headcount <span>60</span></>,
      <>number Offices <span>16</span></>,
      <>Workstations <span>34</span></>,
      <>ADMIN <span>2</span></>,
      <>Focus <span>8</span></>,
    ],
  },
  floorPlan4: {
    img: floorPlanImg5,
    info: [
      [
        <>
          <span>1</span>LIBRArY
        </>,
        <>
          <span>2</span>GARDEN LOUNGE
        </>,
        <>
          <span>3</span>BOARDROOM
        </>,
        <>
          <span>4</span>CONFERENCE ROOM
        </>,
      ],
      [
        <>
          <span>5</span>FITNESS CENTER
        </>,
        <>
          <span>6</span>LOCKER ROOMS
        </>,
        <>
          <span>7</span>CYCLIST LOUNGE & STORAGE (B1)
        </>,
        <>
          <span>8</span>BAR & COCKTAIL LOUNGE
        </>,
      ],
      [
        <>
          <span>9</span>TRACKMAN GOLF SIMULATOR
        </>,
        <>
          <span>10</span>DIRECT ACCESS TO <br /> UNDERGROUND PARKING
        </>,
        <>
          <span>11</span>SECRET GARDEN
        </>,
        <>
          <span>12</span>OUTDOOR FITNESS TURF LAWN
        </>,
      ],
    ],
    infoMob: [
      [
        <>
          <span>1</span>LIBRArY
        </>,
        <>
          <span>2</span>GARDEN LOUNGE
        </>,
        <>
          <span>3</span>BOARDROOM
        </>,
        <>
          <span>4</span>CONFERENCE ROOM
        </>,
        <>
          <span>5</span>FITNESS CENTER
        </>,
        <>
          <span>6</span>LOCKER ROOMS
        </>,
    ],
      [
        <>
          <span>7</span>CYCLIST LOUNGE & STORAGE (B1)
        </>,
        <>
          <span>8</span>BAR & COCKTAIL LOUNGE
        </>,
        <>
          <span>9</span>TRACKMAN GOLF SIMULATOR
        </>,
        <>
          <span>10</span>DIRECT ACCESS TO <br /> UNDERGROUND PARKING
        </>,
        <>
          <span>11</span>SECRET GARDEN
        </>,
        <>
          <span>12</span>OUTDOOR FITNESS TURF LAWN
        </>,
    ],
    ],

  },
  floorPlan5: {
    img: floorPlanImg4,
    info: [
      [
        <>
          <span>13</span>terrace amenity deck
        </>,
        <>
          <span>14</span>terrace amenity lounge
        </>,
        <u key={'id-1'} style={{ display: "block", height: "24px", textDecoration: "none" }}>&nbsp;</u>,
        <u key={'id-2'} style={{ display: "block", height: "24px", textDecoration: "none" }}>&nbsp;</u>,
      ],
    ],
  },
}
