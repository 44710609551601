import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;  
`
export const FixedBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
`
