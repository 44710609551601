import styled from "styled-components";
import {mediaMax} from "../../helpers/MediaQueries";

export const AmenitiesWrapper = styled.div`
  .amenities1-block {
    .content-img-block {
      width: 51%;
      ${mediaMax.phoneXL`
        width: 100%;          
      `}
    }

    .gatsby-image-wrapper {
      height: auto;
      object-fit: unset;
      object-position: unset;
    }
  }
`
