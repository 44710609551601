import styled from "styled-components";
import {mediaMax, mediaMin} from "../../../helpers/MediaQueries";

export const Wrapper = styled.div`
  background-color: #151515;
  height: 100vh;
  ${mediaMax.phoneXL`
    padding: 0 24px;
    min-height: 650px;
    height: 100%;
  `}
  ${mediaMax.phoneL`
    min-height: 600px;
  `}

  .download {
    font-family: 'TT Norms', serif;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.125em;
    text-decoration-line: underline;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      margin: 2px 6px 0 0;
    }
    &.fit {
      position: absolute;
      right: -10%;
      bottom: -16px;
    }
    &.all {
      text-decoration: none;
      border: 1px solid #FFFFFF;
      padding: 8px 28px;
      margin-left: 18px;
      ${mediaMax.phoneXL`
        margin-top: 36px;
      `};
    }
    ${mediaMax.desktopSmall`
      font-size: 11px;    
    `}
  }
`;
export const FPItems = styled.div`
  position: relative;
  height: auto;
`;

export const FPItem = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 9vh;
  transition: all .4s ease-in;
  ${mediaMax.phoneXL`
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0;
    height: max-content;
  `}
`;
export const FPImage = styled.div`
    max-width: 70%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    ${mediaMin.desktopM`
      max-width: 60%;
    `}
    ${mediaMax.phoneXL`
      max-width: 100%;
    `}
`;
export const FPInfo = styled.div`
    margin-top: 6.75vh;
    padding: 0 2.84% 0 2.84%;
    display: flex;
    position: relative;
    justify-content: space-between;
    ${mediaMax.phoneXL`
      flex-direction: column;
      padding: 0;
      margin-top: 36px;
    `}
    .fp-info-level {
      ${mediaMax.phoneXL`
        display: flex;
      `}
        h5 {
          opacity: 0.25;
          font-family: 'TT Norms', serif;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;
          color: #FFFFFF;
          letter-spacing: 0.02em;
          padding-bottom: 12px;
          padding-left: 18px;
          cursor: pointer;
          transition: .4s ease-out;
          &:hover {
            opacity: .75;
          }
          &.active {
            opacity: 1;
          }
          ${mediaMax.phoneXL`
            padding: 0 37px 24px 0;
          `}
        
        }
        a {
          margin-top: 24px;
        }
    }
    .fp-info-items {
        width: 71%;
        display: grid;
        max-width: 1000px;
        grid-template-columns: repeat(3, 1fr);
        min-height: 120px;
        ${mediaMax.phoneXL`
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
        `}
        &.one-r {
            grid-template-columns: repeat(1, 1fr);
            ${mediaMax.phoneXL`
              width: 100%;
              grid-template-columns: repeat(2, 1fr);
              min-height: 30px;
            `}
        }
        p {
            min-height: 45px;
            padding-right: 10px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.1em;
            font-feature-settings: "liga" off;
            padding-bottom: 15px;
            display: flex;
            align-items: center;
            ${mediaMax.phoneXL`
              font-size: 9px;
              padding: 0;
              min-height: 30px;
              br {display: none};
            `}

            &.top-align {
                align-items: center;
                align-self: flex-start;
            }

            span {
                display: block;
                text-align: center;
                line-height: 24px;
                border-radius: 50%;
                margin-right: 11px;
                width: 24px;
                height: 24px;
                color: #292929;
                background-color: #fff;
                flex-shrink: 0;
                ${mediaMax.phoneXL`
                  width: 14px;
                  height: 14px;
                  line-height: 14px;
                  margin-right: 6px;
                `}
            }
        }
    }
    .thumb {
        ${mediaMin.desktopL`
            width: 186px;
        `}
    }

`;