import styled from "styled-components"
import {mediaMax} from "../../helpers/MediaQueries";

interface IStyledProps{
  headingWidth?: string | undefined
  textWidth?: string | undefined
  textHeight?: string | undefined
}
export const Wrapper = styled.div`
  height: 50vh;
  display: flex;
  align-items: flex-end;
  padding: 20px 0 19vh;
  margin-bottom: 20vh; 
  ${mediaMax.phoneXL`
    height: 100%;
    padding: 36px 24px 144px;
    margin-bottom: 0; 
  `}
  .content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    ${mediaMax.phoneXL`
      flex-direction: column;
    `}
  }
`
export const Headings = styled.div<IStyledProps>`
  max-width: 50%;
  opacity: 0;
  transform: translate(0, 10px);
  ${mediaMax.phoneXL`
    max-width: 100%;
    opacity: 1;
    transform: translate(0);
`}

  h3 {
    font-weight: 300;
    max-width: ${props => props.headingWidth? props.headingWidth : '500px'};
  }
`
export const Text = styled.div<IStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transform: translate(0, 100px);
  ${mediaMax.phoneXL`
    opacity: 1;
    width: 100%;
    transform: translate(0);
  `}
  p {
    line-height: ${props => props.textHeight? props.textHeight : '38px'};
    padding: 0 10px;
    max-width: ${props => props.textWidth? props.textWidth : '500px'};
    ${mediaMax.phoneXL`
      padding: 24px 0 0;
      line-height: 1.3;
    `}
  }
`
