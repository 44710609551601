import React, {FC, ReactElement, useEffect, useRef} from "react"
import { Wrapper, Text, Headings } from "./index.styled"
import gsap from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger";

interface IContentHeadingText {
  heading: {
    line1: string | ReactElement
    line2: string | ReactElement
  }
  headingWidth?: string
  text: string[]
  textWidth?: string
  textHeight?: string
}
const ContentAmenitiesHeadingText: FC<IContentHeadingText> = ({ heading, text }) => {
  const wrapperRef = useRef(null)
  const headingRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    const timelinesArray: any[] = []
    ScrollTrigger.matchMedia({
      "(min-width: 1023px)": () => {
        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "top center+=15%",
              end: "bottom+=30% center+=15%",
              scrub: 1,
              toggleActions: "play none none reverse"
            },
          })
          .to(
            headingRef.current || "",
            {opacity: 1, y: 0}
          )
          .to(
            textRef.current || "",
            {opacity: 1},
            "0"
          )
          .addLabel("middle")
          .to(
            headingRef.current || "",
            {opacity: 0},
            "middle"
          )
          .to(
            textRef.current || "",
            {opacity: 0, y: -50},
            "middle"
          )
      }
    })

    return () => {
      timelinesArray.forEach(timeline => timeline.kill())
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <div className="content">
        <Headings ref={headingRef}>
          <h3 className={"h3"}>{heading.line1}</h3>
          <h3 className={"h3i"}>{heading.line2}</h3>
        </Headings>
        <Text ref={textRef}>
          {text.map((item, i) => (
            <p className="copy_t1" key={`id-${i}`}>
              {item}
            </p>
          ))}
        </Text>
      </div>
    </Wrapper>
  )
}

export default ContentAmenitiesHeadingText
