import React, {FC, useContext, useEffect, useRef} from "react"
import {FixedBackground, Wrapper} from "./index.styled"
import mainImage from "../../../assets/images/amenities/amenities_10_masked.jpg";
import {Context} from "../../../context/context";
import gsap from "gsap";

const AmenitiesFixedBackground: FC = () => {
  const {setMenuTheme} = useContext(Context);
  const wrapperRef = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef?.current || "",
        toggleActions: "play none none reverse",
        start: "top top",
        end: "bottom top",
        onLeave: () => {
          setMenuTheme("full-light")
        },
        onEnter: () => {
          setMenuTheme("full-dark")
        },
        onLeaveBack: () => {
          setMenuTheme("full-light")
        },
        onEnterBack: () => {
          setMenuTheme("full-dark")
        }
      },
    })
    return () => {
      tl.kill()
    }
  }, [])
  return (
    <Wrapper ref={wrapperRef}>
      <FixedBackground style={{backgroundImage: `url(${mainImage})`}}/>
    </Wrapper>
  )
}

export default AmenitiesFixedBackground
