import React, {useContext, useEffect} from "react"
import {quoteData} from "../data/intro"
import {spacesData} from "../data/spaces"
import {headText} from "../data/head-text"
import {ImgTextData} from "../data/img-text"
import {Context} from "../context/context";
import ContentIntro from "../components/content-intro"
import ContentQuote from "../components/content-quote"
import ContentFpAm from "../components/amenities/content-fp-am"
import ContentImageZoomed from "../components/content-image-zoomed"
import ContentSpaces from "../components/amenities/content-spaces"
import ContentImgText from "../components/content-img-text"
import Footer from "../components/footer"
import Image from "../components/image";
import ContentAmNext from "../components/amenities/content-amenities-next"
import {AmenitiesWrapper} from "../components/amenities/index.styled"
import ContentIntroZoomed from "../components/content-intro-zoomed";
import AmenitiesIntro from "../components/amenities/amenities-intro";
import ContentAmenitiesHeadingText from "../components/amenities/content-amenities-heading-text";
import AmenitiesFixedBackground from "../components/amenities/amenities-fixed-background";
import useWindowSize from "../helpers/windowSize";
import ContentHeadingTextImage from "../components/content-heading-text-image";
import ContentMasked from "../components/content-masked";

const AmenitiesPage = () => {
  const {setMenuTheme} = useContext(Context);
  const {width} = useWindowSize();

  useEffect(() => {
    setMenuTheme("full-light")
  }, []);
  return (

    <AmenitiesWrapper className={"relative"}>
      <ContentIntro imgName={width > 576 ? "amenities_1.jpg" : "amenities_1_mob.jpg"} objPosition={'center 35% !important'} scrollArrow={true}/>
      {/* {width > 576 ?
        <AmenitiesIntro imgName={'amenities_1.jpg'}/>
        :
        <ContentIntro imgName={"amenities_1_mob.jpg"} scrollArrow={true}/>
      } */}
      <section className="hide-logo">
        <ContentQuote quote={quoteData.amenities1}/>
      </section>
      {/* {width > 576 ?
        <ContentIntroZoomed/>
        :
        <Image imageName={"amenities_2.jpg"}/>
      } */}
      {width > 576 ?
        <>
          <ContentAmenitiesHeadingText
            heading={headText.amenities1.heading}
            text={headText.amenities1.text}
          />
          <ContentIntro imgName={'amenities_3.jpg'}/>
        </>
        :
        <div className={'m-pt-70 m-pb-70'}>
          <ContentHeadingTextImage
            heading={headText.amenities1.heading}
            text={headText.amenities1.text}
            image={'amenities_3_mob.jpg'}
            headingsPadding={"0 24px 34px"}
          />
        </div>
      }
      <ContentSpaces spaces={spacesData.spaces1}/>
      <ContentSpaces spaces={spacesData.spaces2}/>
      <ContentSpaces spaces={spacesData.spaces4}/>
      <ContentSpaces spaces={spacesData.spaces5}/>
      <ContentSpaces spaces={spacesData.spaces3}/>
      {width > 576 ?
        <AmenitiesFixedBackground/>
        :
        <div className={'m-pt-90'}>
          <ContentMasked imgName={"amenities_10_masked_mob.jpg"}/>
        </div>
      }
      <ContentQuote quote={quoteData.amenities2}/>
      {width > 576 ?
        <ContentIntro imgName={'amenities_11.jpg'}/>
        :
        <Image imageName={'amenities_11_mob.jpg'}/>
      }
      <ContentAmenitiesHeadingText heading={headText.amenities2.heading} text={headText.amenities2.text}/>
      <section className={width > 577 ? 'amenities1-block' : ''}>
        <ContentImgText data={ImgTextData.amenities1} letterSpacing={'0'} mobFullWidth={true}/>
      </section>
      <ContentImgText data={ImgTextData.amenities2} letterSpacing={'0'} imgMaxWidth={'50%'} mobFullWidth={true} isHeightAuto={true}/>
      <ContentImgText data={ImgTextData.amenities3} letterSpacing={'0'} mobFullWidth={true}/>
      <ContentImageZoomed/>
      <section>
        <ContentFpAm/>
      </section>
      <ContentAmNext quote={quoteData.amenities3}/>
      <Footer/>
    </AmenitiesWrapper>
  )
}

export default AmenitiesPage
