import React, {FC, useEffect, useRef} from "react"
import {FixedBackground, Wrapper} from "./index.styled"
import gsap from "gsap"
import mainImage from "../../assets/images/amenities/amenities_15.jpg";
import ContentHeadingText from "../content-heading-text";
import Image from "../image";
import {headText} from "../../data/head-text";
import {sizes} from "../../helpers/MediaQueries";
import useWindowSize from "../../helpers/windowSize";
const ContentImageZoomed: FC = () => {
  const wrapperRef = useRef(null)
  const fixedBackgroundRef = useRef(null)
  const {width} = useWindowSize();
  useEffect(() => {
    if (window.screen.width < sizes.phoneXL) return
    const tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: wrapperRef.current || "",
          start: "top top",
          end: "top+=30% top",
          scrub: 0.5,
          toggleActions: "play none none reverse",
        },
      })
      .to(fixedBackgroundRef.current, {
        backgroundSize: "100%"
      }, "0")
    return () => {
      tl.kill()
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>

      {width > sizes.phoneXL ?
        <FixedBackground style={{backgroundImage: `url(${mainImage})`}} ref={fixedBackgroundRef}/>
        :
        <Image imageName={'amenities_15_mob.jpg'}/>
      }
      <ContentHeadingText
        heading={headText.amenities3.heading}
        headingWidth={'520px'}
        text={headText.amenities3.text}
        textWidth={'300px'}
        textHeight={'27px'}
      />
    </Wrapper>
  )
}

export default ContentImageZoomed
